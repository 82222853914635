

const NotExist = () => {
    return (
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-green-500">404</p>

          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-100 sm:text-5xl">Page not found</h1>

          <p className="mt-6 text-base leading-7 text-gray-300">Sorry, the page you're looking for doesn't exist.</p>
        </div>
      </main>
    );
}

export default NotExist;